.app {
  min-height: 100vh;
  background-color: #f3f4f6;
  padding: 2rem 1rem;
}

.palette-container {
  max-width: 28rem;
  margin: 0 auto;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.palette {
  padding: 1rem;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.color-boxes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.color-box {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 0 1rem;
}

.copy-button {
  background-color: white;
  color: #1f2937;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #e5e7eb;
}

.generate-button {
  margin-top: 1rem;
  width: 100%;
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.generate-button:hover {
  background-color: #2563eb;
}